import axios from 'axios';
import { Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate'
import $ from 'jquery';
import React, { Component, useState } from 'react';
import Cookies from 'universal-cookie';
import "../services/ApiRest";
import swal from 'sweetalert';
import { ApiUrl } from '../services/ApiRest';
import { Alert } from 'bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as xlsx from "xlsx";

const cookie = new Cookies();
const urlListaAsignatutasDoc = ApiUrl + "listarasigdocperiod" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlListarPeriodos = ApiUrl + "listperiodostodos" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const listarEstAsignatura = ApiUrl + "listestasignatura" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlModCalificacionEstAsign = ApiUrl + "modcalificacionestasign" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";
const urlIngresarCalificacionesAsign = ApiUrl + "ingcalificacionestasign" + "?api_key=7d3654c0-3d52-447c-8893-eda66cddfa71";

let listaauxiliar = [];
const itemsPerPage = 3;

export default class ListarAsignaturasDoc extends Component {
    constructor(props) {
        super(props)
        this.state = {
            estud: [],
            EstudianteBackup: [],
            ListEstAsign: [],
            ListEstAsignExcel: [],
            ListAsign: [],
            //variables para paginacion
            currentItems: null,
            pageCount: 0,
            itemOffset: 0,
            count: 0,
            //variables globales
            form1: {
            },
            listaperiodos: [],
            periodo: 1,
            showResults: "none",
        }
        this.handleChange = this.handleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.setState({ modalRegistroPagoMatricula: false });
            this.setState({ modalModUsuario: false });
        }
    }
    handleChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        this.setState({ estado: "" });
        this.setState({ imgpersonalmod: e.target.files })

    }

    //controles del modal de  un registro de abono de matricula
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, true);


        axios.get(urlListarPeriodos)
            .then(response => {
                const respuesta = response.data.data;
                this.setState({ listaperiodos: respuesta });
            })
        axios.post(urlListaAsignatutasDoc, {
            idDocente: cookie.get("idAdministrativo"),
            idPeriodoSelect: this.state.periodo,
        })
            .then(respuesta => {
                this.setState({ ListAsign: respuesta.data.data });
                //FIN DE LA PAGINACION
            })

    }


    //FIN DE PAGINACION
    ocultarModalModificar = () => {
        this.setState({ modalModificar: false });
    }
    ocultarModificarUsu = () => {
        this.setState({ modalModUsuario: false });
    }


    //Listar las asignaturas de los docentes
    handleAsignaturasPeriod = async (e) => {

        await this.setState({ [e.target.name]: e.target.value })
        axios.get(urlListaAsignatutasDoc)
            .then(response => {
                const listroles = response.data.data;
                this.setState({ listroles });
            })

        axios.post(urlListaAsignatutasDoc, {
            idDocente: cookie.get("idAdministrativo"),
            idPeriodoSelect: this.state.periodo,
        })
            .then(respuesta => {
                this.setState({ ListAsign: respuesta.data.data });
            })
            .catch(error => {
                this.setState({ error: "No existe conexión con el servidor" });
                swal({
                    text: "No existe conexión con el servidor",
                    icon: "error",
                    button: "Aceptar",
                });
            });
    }


    mostrarModalModUsu = (registro) => {
        this.setState({ modalModUsuario: true, form1: registro });
    }

    seleccionarAsignuno = () => {
        var id_asignatura = 0;
        var id_paralelo = 0;
        this.setState({ showResults: "block" });
        $(".afiliado:checked").each(function () {
            id_asignatura = $(this).parent().parent().find('td').eq(0).html();
            id_paralelo = $(this).parent().parent().find('td').eq(1).html();
        });
        axios
            .post(listarEstAsignatura, {
                idAsignatura: id_asignatura,
                idParalelo: id_paralelo,
                idPeriodo: this.state.periodo
            })
            .then(response => {
                this.setState({ ListEstAsignExcel: response.data.data });
                listaauxiliar = response.data.data;
                this.state.itemOffset = 0;
                //PAGINACION
                const endOffset = this.state.itemOffset + itemsPerPage;
                this.setState({ currentItems: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ EstudianteBackup: listaauxiliar.slice(this.state.itemOffset, endOffset) });
                this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
                this.setState((state) => {
                    return { itemOffset: state.itemOffset + endOffset }
                });
            })
    }

    ocultarModalModCalificacionEst = () => {
        this.setState({ modalmodCalificacionEst: false });
    }

    actualizarValoresModal = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        var promparciales = this.promedioparciales(Number(this.state.parcial1), Number(this.state.parcial2), Number(this.state.parcial3));
        var promfinal = this.promediototal(Number(this.state.parcial1), Number(this.state.parcial2), Number(this.state.parcial3), Number(this.state.examenf), Number(this.state.examens));
        var equiv = promfinal < 7 ? "REPROBADO" : "APROBADO";
        $(function () {
            $("#proma").val(promparciales);
            $("#promf").val(promfinal);
            $("#equivalencia").val(equiv);
        });
        this.setState({ proma: promparciales });
        this.setState({ promf: promfinal });
        this.setState({ equivalencia: equiv });
    }

    mostrarModalModCalificacionEst = (registro) => {
        var promparciales = this.promedioparciales(registro.parcial1, registro.parcial2, registro.parcial3);
        var promfinal = this.promediototal(registro.parcial1, registro.parcial2, registro.parcial3, registro.examen_final, registro.examen_suspension);
        var parcial1convert=this.convertirDosDecimales(registro.parcial1);
        var parcial2convert=this.convertirDosDecimales(registro.parcial2);
        var parcial3convert=this.convertirDosDecimales(registro.parcial3);
        $(function () {
            $("#id").val(registro.id_matricula_asignatura);
            $("#estudiante").val(registro.primer_nombre_estudiante + " " + registro.segundo_nombre_estudiante + " " + registro.primer_apellido_estudiante + " " + registro.segundo_apellido_estudiante);
            $("#parcial1").val(parcial1convert);
            $("#parcial2").val(parcial2convert);
            $("#parcial3").val(parcial3convert);
            $("#proma").val(promparciales);
            $("#examenf").val(registro.examen_final);
            $("#examens").val(registro.examen_suspension);
            $("#promf").val(promfinal);
            $("#equivalencia").val(registro.equivalencia == 0 ? "REPROBADO" : "APROBADO");
            $("#asistencia").val(registro.asistencia);
        });
        this.setState({ modalmodCalificacionEst: true, form1: registro });
        this.setState({ id: registro.id_matricula_asignatura });
        this.setState({ estudiante: registro.primer_nombre_estudiante + " " + registro.segundo_nombre_estudiante + " " + registro.primer_apellido_estudiante + " " + registro.segundo_apellido_estudiante });
        this.setState({ parcial1: registro.parcial1 });
        this.setState({ parcial2: registro.parcial2 });
        this.setState({ parcial3: registro.parcial3 });
        this.setState({ proma: promparciales });
        this.setState({ examenf: registro.examen_final });
        this.setState({ examens: registro.examen_suspension });
        this.setState({ promf: promfinal });
        this.setState({ equivalencia: registro.equivalencia == 0 ? "REPROBADO" : "APROBADO" });
        this.setState({ asistencia: registro.asistencia });
    }

    promediototal = (p1, p2, p3, ef, es) => {
        var promp = (p1 + p2 + p3) / 3;
        var promp = (promp * 6) / 10;
        var examfinal = (ef * 4) / 10;
        var promacumulado = promp + examfinal;
        //  var promfinal = promacumulado >= 7 ? promacumulado : (promacumulado + es) / 2;
        var promfinal = 0.00;
        if (promacumulado >= 7) {
            promfinal = promacumulado;
        }
        else {
            if (((promacumulado >= 4) && (promacumulado < 7)) && (es == 0)) {
                promfinal = promacumulado;
            }
            else {

                if (((promacumulado >= 4) && (promacumulado < 7)) && (es > 0)) {
                    promfinal = (promacumulado + es) / 2;

                }
                else {
                    promfinal = promacumulado;
                }
            }
        }

        return (promfinal.toFixed(2))
    }
     //Convertir a dos decimales
   
     convertirDosDecimales = (nota) => {
        return (nota.toFixed(2))
    }

    promedioparciales = (p1, p2, p3) => {
        var promp = (p1 + p2 + p3) / 3;
        var promp = (promp * 6) / 10;
        return (promp.toFixed(2))
    }

    promediosobre4 = (p10) => {
        var prom4 = (p10 * 4) / 10;
        return (prom4.toFixed(2))
    }

    modificarCalificacionEstAsignatura = () => {
        var combmsmalert = "¿Está seguro que desea modificar la calificación?";
        var equiv = 0;
        if (Number(this.state.promf) >= 7) {
            equiv = 1;
        }

        swal({
            title: "Advertencia",
            text: combmsmalert,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    axios
                        .post(urlModCalificacionEstAsign, {
                            id: this.state.id,
                            parcial1: this.state.parcial1,
                            parcial2: this.state.parcial2,
                            parcial3: this.state.parcial3,
                            examenf: this.state.examenf,
                            examens: this.state.examens,
                            equivalencia: equiv,
                            asistencia: this.state.asistencia,
                        })
                        .then(response => {
                            if (response.data.success === true) {
                                swal({
                                    text: "Datos procesados correctamente",
                                    icon: "success",
                                    button: "Aceptar",
                                });
                                this.seleccionarAsignuno();
                            } else {
                                swal({
                                    text: "No se actualizó los datos",
                                    icon: "error",
                                    button: "Aceptar",
                                });
                                this.setState({ modalmodDocAsignatura: false });

                            }
                        })
                        .catch(error => {
                            this.setState({ error: "No existe conexión con el servidor" });
                            swal({
                                text: "No existe conexión con el servidor",
                                icon: "error",
                                button: "Aceptar",

                            });
                        });
                } else {
                    swal({
                        text: "¡Haz denegado la acción!",
                        icon: "warning",
                        button: "Aceptar",
                    });
                }
            });
    }

    readUploadFile = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })
        //e.preventDefault();
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            }
        };
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                console.log(json);
                axios.post(urlIngresarCalificacionesAsign, {
                    calificaciones: json,
                }, axiosConfig)
                    .then(response => {
                        if (response.data.success === true) {
                            swal({
                                text: "Datos procesados correctamente",
                                icon: "success",
                                button: "Aceptar",
                            });
                            this.seleccionarAsignuno();
                        } else {
                            swal({
                                text: "No se actualizó los datos",
                                icon: "error",
                                button: "Aceptar",
                            });
                        }
                    })
                    .catch(error => {
                        this.setState({ error: "No existe conexión con el servidor" });
                        swal({
                            text: "No existe conexión con el servidor",
                            icon: "error",
                            button: "Aceptar",

                        });
                    });
            };
            reader.readAsArrayBuffer(e.target.files[0]);
            $(function () {
                $("#upload").val('');
            });
        }
    }

    //FUNCION DE PAGINACION POR  MEDIO DE UN CLIC
    handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listaauxiliar.length;
        this.setState((state) => {
            return { itemOffset: newOffset }
        });
        const endOffset = newOffset + itemsPerPage;
        this.setState({ currentItems: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ EstudianteBackup: listaauxiliar.slice(newOffset, endOffset) });
        this.setState({ pageCount: Math.ceil(listaauxiliar.length / itemsPerPage) });
        this.setState((state) => {
            // Importante: lee state en vez de this.state al actualizar.
            return { itemOffset: newOffset }
        });
    }

    //BUSQUEDA......
    filter(event) {
        console.log(event.target.value);
        //obtener datos de buscar
        var text = event.target.value
        //obtener datos del array
        const data = this.state.ListEstAsignExcel;
        var newData = data.filter(function (item) {
            //variable del objeto
            const itemDataPrimerApell = item.primer_apellido_estudiante.toUpperCase()
            const itemDataPrimerNom = item.primer_nombre_estudiante.toUpperCase()
            const itemDataCedula = item.numero_identificacion_estudiante.toUpperCase()
            const campo = itemDataPrimerApell + " " + itemDataPrimerNom + " " + itemDataCedula;
            //variable del buscador
            const textData = text.toUpperCase()
            return campo.indexOf(textData) > -1
        })
        if (text == "") {
            newData = this.state.EstudianteBackup;
        }

        this.setState({
            currentItems: newData,
            //textBuscar: text,
        })
        console.log(this.state.currentItems)
    }
   
    render() {
        return (

            <div className="row animate__animated animate__fadeIn">
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{ }</li>
                </ol>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="card-header back-istmas">
                        <i className="far fa-sticky-note"></i>
                        <b>-Calificaciones de Asignaturas </b>
                    </div>
                    <div>
                    </div>
                    <div className="card-body">
                        <div className="row" >
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                <div className="mb-3">
                                    <label className="form-label"><b>Periodo Académico:</b></label>
                                    <select className="form-select" id="periodo" name="periodo" value={this.state.value} onChange={this.handleAsignaturasPeriod} aria-label="Default select example" autocomplete="off" required>
                                        <option value="undefined" selected="true" disabled>Seleccione el periodo:</option>
                                        {this.state.listaperiodos.map(consola => (
                                            <option value={consola.id_periodo_academico}>{consola.descripcion_periodo_academico}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        <b>ASIGNATURAS</b>
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>


                                        <div class="table-responsive">
                                            <table className="table  text-left"  >
                                                <thead >
                                                    <tr >
                                                        <th scope="col">Carrera</th>
                                                        <th scope="col">Nivel</th>
                                                        <th scope="col">Código</th>
                                                        <th scope="col">Asignatura</th>
                                                        <th scope="col">Paralelo</th>
                                                        <th scope="col">Fecha Inicio</th>
                                                        <th scope="col">Fecha Fin</th>
                                                        <th scope="col">Detalle</th>
                                                        <th scope="col">Estado Acta</th>
                                                        <th scope="col">Seleccionar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.ListAsign.map((consola) => (
                                                        <tr class="list-afiliados" key={this.state.estud.id_asignatura}  >
                                                            <td class="oculto">{consola.id_asignatura}</td>
                                                            <td class="oculto">{consola.paralelo}</td>
                                                            <td >{consola.descripcion_carrera}</td>
                                                            <td>{consola.nivel_asignatura}</td>
                                                            <td>{consola.codigo_asignatura}</td>
                                                            <td>{consola.descripcion_asignatura}
                                                            </td>
                                                            <td > {consola.nombre_paralelo}
                                                            

                                                            </td>
                                                            <td>{consola.fecha_inicio_asignatura}</td>
                                                            <td>{consola.fecha_fin_asignatura}</td>
                                                            <td>{consola.detalle}</td>
                                                            <td>
                                                                {(() => {
                                                                    switch (consola.estadoacta) {
                                                                        case 0: return <h6> Pendiente</h6>;
                                                                        case 1: return <h6>Entregado</h6>;
                                                                    }
                                                                })()}
                                                            </td>
                                                            <td>
                                                                <input type="radio" value="1" name="afiliado" onChange={this.seleccionarAsignuno} class="afiliado" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ display: this.state.showResults }}>
                            <div className="col-12 col-sm-12 col-lg-11 col-xl-11 centrar" >
                                <div className="card text-center ">
                                    <div className="card-header ">
                                        <b>ESTUDIANTES DE LA ASIGNATURA</b>
                                    </div>
                                    <div className="row ">
                                        <div className="col-12 col-sm-12 col-lg-5 col-xl-5 centrar" >
                                            <div className="mb-3">
                                                <input class="form-control col-md-8" value={this.state.text} placeholder="Buscar..." onChange={(text) => this.filter(text)} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12  col-lg-5 col-xl-5 centrar" >
                                        </div>
                                    </div>
                                    <div className="card-body centrar " style={{ width: "100%" }}>
                                        <div class="table-responsive">
                                            <table id="tablaasignaturas" className="table text-left">
                                                <thead >
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">ESTUDIANTE</th>
                                                        <th scope="col">P1</th>
                                                        <th scope="col">P2</th>
                                                        <th scope="col">P3</th>
                                                        <th scope="col">PROMEDIO PARCIAL / 6</th>
                                                        <th scope="col">EXAMEN FINAL / 4</th>
                                                        <th scope="col">SUSPENSIÓN / 10</th>
                                                        <th scope="col">PROMEDIO FINAL / 10</th>
                                                        <th scope="col">ESTADO</th>
                                                        <th scope="col">%ASISTENCIA</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.currentItems &&
                                                        this.state.currentItems.map(consola => (
                                                            <tr key={consola.id_matricula_asignatura}>
                                                                <td class="mes">{consola.id_matricula_asignatura}</td>
                                                                <td class="mes">{consola.primer_apellido_estudiante + " " + consola.segundo_apellido_estudiante + " " + consola.primer_nombre_estudiante + " " + consola.segundo_nombre_estudiante}</td>
                                                                <td class="mes">{this.convertirDosDecimales(consola.parcial1)}</td>
                                                                <td class="mes">{this.convertirDosDecimales(consola.parcial2)}</td>
                                                                <td class="mes">{this.convertirDosDecimales(consola.parcial3)}</td>
                                                                <td class="mes">{this.promedioparciales(consola.parcial1, consola.parcial2, consola.parcial3)}</td>
                                                                <td class="mes">{this.promediosobre4(consola.examen_final)}</td>
                                                                <td class="mes">{consola.examen_suspension}</td>
                                                                <td class="mes">{this.promediototal(consola.parcial1, consola.parcial2, consola.parcial3, consola.examen_final, consola.examen_suspension)}</td>
                                                                <td class="mes">{consola.equivalencia == 0 ? "REPROBADO" : "APROBADO"}</td>
                                                                <td class="mes">{consola.asistencia}</td>
                                                                <td class="detalle">
                                                                    <button onClick={() => this.mostrarModalModCalificacionEst(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                            <table id="tablaasignaturasexcel" className="table text-left" hidden>
                                                <thead >
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">ESTUDIANTE</th>
                                                        <th scope="col">P1</th>
                                                        <th scope="col">P2</th>
                                                        <th scope="col">P3</th>
                                                        <th scope="col">PROMEDIO_PARCIAL_6</th>
                                                        <th scope="col">EXAMEN_FINAL_10</th>
                                                        <th scope="col">SUSPENSION_10</th>
                                                        <th scope="col">PROMEDIO FINAL / 10</th>
                                                        <th scope="col">ESTADO</th>
                                                        <th scope="col">ASISTENCIA</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.ListEstAsignExcel.map(consola => (
                                                        <tr key={consola.id_matricula_asignatura}>
                                                            <td class="mes">{consola.id_matricula_asignatura}</td>
                                                            <td class="mes">{consola.primer_apellido_estudiante + " " + consola.segundo_apellido_estudiante + " " + consola.primer_nombre_estudiante + " " + consola.segundo_nombre_estudiante}</td>
                                                            <td class="mes">{this.convertirDosDecimales(consola.parcial1)}</td>
                                                            <td class="mes">{this.convertirDosDecimales(consola.parcial2)}</td>
                                                            <td class="mes">{this.convertirDosDecimales(consola.parcial3)}</td>
                                                            <td class="mes">{this.promedioparciales(consola.parcial1, consola.parcial2, consola.parcial3)}</td>
                                                            <td class="mes">{consola.examen_final}</td>
                                                            <td class="mes">{consola.examen_suspension}</td>
                                                            <td class="mes">{this.promediototal(consola.parcial1, consola.parcial2, consola.parcial3, consola.examen_final, consola.examen_suspension)}</td>
                                                            <td class="mes">{consola.equivalencia == 0 ? "REPROBADO" : "APROBADO"}</td>
                                                            <td class="mes">{consola.asistencia}</td>
                                                            <td class="detalle">
                                                                <button onClick={() => this.mostrarModalModCalificacionEst(consola)} > <i className=" fas fa-clipboard-list" ></i></button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div class="table-responsive">
                                                <ReactPaginate
                                                    nextLabel="next >"
                                                    onPageChange={this.handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={2}
                                                    previousLabel="< previous"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    breakLabel="..."
                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    renderOnZeroPageCount={null}
                                                />
                                            </div>

                                            <div className="row ">
                                                <div className="col-12 col-sm-12 col-lg-5 col-xl-3 centrar" >
                                                    <div className="mb-3">

                                                        <ReactHTMLTableToExcel
                                                            id="botonExportarExcel"
                                                            className="btn btn-success"
                                                            table="tablaasignaturasexcel"
                                                            filename="matrizcalificacionesexcel"
                                                            sheet="pagina 1 "
                                                            buttonText="Descargar Plantilla"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-lg-5 col-xl-3 centrar" >
                                                    <div className="mb-3">
                                                        <input
                                                            type="file"
                                                            name="upload"
                                                            id="upload"
                                                            class="boton_file"
                                                            onChange={this.readUploadFile}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-lg-5 col-xl-3 centrar" >
                                                    <div className="mb-3">

                                                     
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="table-responsive">
                                                <Modal isOpen={this.state.modalmodCalificacionEst} >
                                                    <ModalHeader>
                                                        Asignatura
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        <FormGroup>
                                                            <div className="mb-3 centrar">
                                                                <input name="id" id="id" type="hidden" onChange={this.handleChange} ></input>
                                                                <label className="form-label">Estudiante</label><br />
                                                                <input type="text" id="estudiante" name="estudiante" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required disabled />
                                                                <label className="form-label">Parcial 1</label><br />
                                                                <input type="text" id="parcial1" name="parcial1" onChange={this.actualizarValoresModal} className="form-control" placeholder="" autocomplete="off" required />
                                                                <label className="form-label">Parcial 2</label><br />
                                                                <input type="text" id="parcial2" name="parcial2" onChange={this.actualizarValoresModal} className="form-control" placeholder="" autocomplete="off" required />
                                                                <label className="form-label">Parcial 3</label><br />
                                                                <input type="text" id="parcial3" name="parcial3" onChange={this.actualizarValoresModal} className="form-control" placeholder="" autocomplete="off" required />
                                                                <label className="form-label">Promedio Acumulado</label><br />
                                                                <input type="text" id="proma" name="proma" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required disabled />
                                                                <label className="form-label">Examen Final</label><br />
                                                                <input type="text" id="examenf" name="examenf" onChange={this.actualizarValoresModal} className="form-control" placeholder="" autocomplete="off" required />
                                                                <label className="form-label">Examen Suspensión</label><br />
                                                                <input type="text" id="examens" name="examens" onChange={this.actualizarValoresModal} className="form-control" placeholder="" autocomplete="off" required />
                                                                <label className="form-label">Promedio Final</label><br />
                                                                <input type="text" id="promf" name="promf" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required disabled />
                                                                <label className="form-label">Estado</label><br />
                                                                <input type="text" id="equivalencia" name="equivalencia" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required disabled />
                                                                <label className="form-label">Asistencia</label><br />
                                                                <input type="text" id="asistencia" name="asistencia" onChange={this.handleChange} className="form-control" placeholder="" autocomplete="off" required />
                                                            </div>
                                                        </FormGroup>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <button class="btn btn-outline-primary" onClick={this.modificarCalificacionEstAsignatura} > Modificar</button>
                                                        <button class="btn btn-outline-primary" onClick={this.ocultarModalModCalificacionEst}>Salir</button>
                                                    </ModalFooter>
                                                </Modal>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

